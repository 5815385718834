<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y:scroll">
        <EditAppointmentModal @cancelEdit="cancelEditAppointment" @updated="updatedAppointment" />
		<div class="dF aC">
            <h4 class="mb-3 mt-3">Appointments</h4>
			<div v-if="selectedRowKeys.length && selectedRowKeys.length > 0" class="dF aC">
				<div class="ml-3" style="color:var(--orange)">{{`${selectedRowKeys.length} selected`}}</div>
				<div @click="bulkDelete" class="py-1 px-3 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer;">Delete</div>
			</div>
        </div>
        <template v-if="appointments.length">
            <a-table :pagination="false" :title="null" @change="handleChange" :row-selection="rowSelection" :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1350 }" :columns="columns" :dataSource="appointments">
                <div slot="name" slot-scope="obj">{{obj.title}}</div>
                <div slot="date" slot-scope="obj">{{convertDate(obj.date)}}</div>
                <div slot="startTime" slot-scope="obj">{{convertTime(obj.startTime)}}</div>
                <div slot="endTime" slot-scope="obj">{{convertTime(obj.endTime)}}</div>
                <div slot="contact" slot-scope="obj">
                    <div @click="$router.push(`/leads/${obj.contact.id}`)" style="color:var(--orange); cursor: pointer;" v-if="obj.contact && obj.contact.email">{{obj.contact.email}}</div>
                    <div style="color:var(--orange); cursor: pointer;" v-else><span @click="goToContact(obj.opportunity)">{{obj.opportunity && obj.opportunity.contact ? getContact(obj.opportunity) : ''}}</span></div>
                </div>
                <div slot="type" slot-scope="obj">
                    <div v-if="obj.type && obj.type != ''">{{getType(obj.type)}}</div>
                </div>
                <div slot="location" slot-scope="obj">{{obj.location ? obj.location : ''}}</div>
                <div slot="reminder" slot-scope="obj">{{obj.reminderType && obj.reminderType != '' && reminder[obj.reminderType] && obj.reminderType != 'onetime' ? reminder[obj.reminderType] : obj.reminderType && obj.reminderType == 'onetime' && obj.reminderOn != 0 ? `${convertDate(obj.reminderOn)} | ${convertTime(obj.reminderOn)}` : ''}}</div>
                <div slot="actions" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                        <div slot="content">
                            <div @click="$store.commit('OPEN_PREVIEW_MODAL', {type:'appointment', object:obj})" class="popoverContent dF aC"><a-icon type="eye" class='mr-2' /><div>Preview Appointment</div></div>
                            <div @click="editAppointment(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg><div>Edit Appointment</div></div>
                            <!-- <div class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-2 feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg><div>Copy Appointment</div></div> -->
                            <div @click="deleteAppointment(obj)" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete Appointment</div></div>
                        </div>
                        <div class="more-option-icon mr-4">
                            <a-icon style="line-height: 40px;" type="more" />
                        </div>
                    </a-popover>
                </div>
            </a-table>
        </template>
        <template v-else>
            <EmptyApp :text="'You don\'t have any appointments'" :img="require('@/assets/emptyIcons/appointments.png')" />
        </template>
    </div>
</template>

<script>
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import EditAppointmentModal from '@/components/contacts/EditAppointmentModal'
import moment from 'moment'
import 'moment-timezone';
export default {
    components:{
        EmptyApp,EditAppointmentModal
    },
    computed:{
		timezone(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeZone && typeof this.$store.state.contacts.allSettings.user.options.regional.timeZone == 'string' ? this.$store.state.contacts.allSettings.user.options.regional.timeZone : 'America/New_York'
		},
		timeFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeFormat ? this.$store.state.contacts.allSettings.user.options.regional.timeFormat : 12
		},
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        opportunities() {
            return this.$store.state.contacts.opportunities || []
        },
        appointments() {
            let refresh = this.test
            let appointments = this.$store.state.contacts.appointments || []
            if (this.sortItem.key && this.sortItem.order && this.sortItem.keys != '') {
                if (this.sortItem.key == 'date') {
                    if (this.sortItem.order == 'ascend') {
                        appointments = appointments.sort((a,b) => {
                            if (a.date > b.date) return 1
                            if (a.date < b.date) return -1
                            else return 0
                        })
                    } else if (this.sortItem.order == 'descend') {
                        appointments = appointments.sort((a,b) => {
                            if (a.date > b.date) return -1
                            if (a.date < b.date) return 1
                            else return 0
                        })
                    }
                }
            }
            return appointments
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: false,
                onSelection: this.onSelection,
            };
        },
        appointmentTypes() {
            return [
				{ name: 'Virtual Meeting', id: 'virtualmeeting' },
				{ name: 'Phone Meeting', id: 'phonemeeting' },
				{ name: 'Sales Center', id: 'salescentre' }
			]
        },
    },
    data() {
        return{
            test:0,
            sortItem:{},
            columns:[
                { title: 'Title', key: 'name', scopedSlots: { customRender: 'name' },},
                { title: 'Date', key: 'date', scopedSlots: { customRender: 'date' }, sorter:true},
                { title: 'Start Time', key: 'startTime', scopedSlots: { customRender: 'startTime' },},
                { title: 'End Time', key: 'endTime', scopedSlots: { customRender: 'endTime' },},
                { title: 'Primary Contact', key: 'contact', scopedSlots: { customRender: 'contact' },},
                { title: 'Appointment Type', key: 'type', scopedSlots: { customRender: 'type' },},
                { title: 'Location', key: 'location', scopedSlots: { customRender: 'location' },},
                { title: 'Reminder', key: 'reminder', scopedSlots: { customRender: 'reminder' },},
                { title: '', key: 'actions', scopedSlots: { customRender: 'actions' },},
            ],
            selectedRowKeys:[],
            reminder : {
                "r15m":'15 Minutes',
                "r30m":'30 Minutes',
                "r1h":'1 Hour',
                "r2h":'2 Hours',
                "r6h":'6 Hours',
                "r12h":'12 Hours',
                "r1d":'1 Day',
                "r2d":'2 Days',
                "r1w":'1 Week',
                "onetime":'One-time'
            }
        }
    },
    methods:{
		moment,
		goToContact(opportunity){
            if (typeof opportunity == 'object') {
                let find = this.opportunities.find(x => x.id == opportunity.id)
                if (find && find.contact) {
					return this.$router.push(`/leads/${find.contact.id}`);
                }
            } else if (typeof opportunity == 'string') {
                let find = this.opportunities.find(x => x.id == opportunity)
                if (find && find.contact) {
                    return this.$router.push(`/leads/${find.contact.id}`);
                }
            }
		},
		offsetDiffMs(type){
			let desiredTimezone = moment.tz(this.timezone);
			let desiredOffset = desiredTimezone.utcOffset();
			let currentOffset = new Date().getTimezoneOffset() * -1;
			let offsetDiff = 0
			let offsetDiffMs = 0
			if (currentOffset !== desiredOffset){
				if (type == 'set'){
					offsetDiff = currentOffset - desiredOffset;
				} else {
					offsetDiff = desiredOffset - currentOffset;
				}
				offsetDiffMs = offsetDiff * 60 * 1000;
			}
			return offsetDiffMs
		},
        getContact(opportunity){
            let info = ''
            if (typeof opportunity == 'object') {
                let find = this.opportunities.find(x => x.id == opportunity.id)
                if (find && find.contact) {
                    if (find.contact.firstName && find.contact.lastName && find.contact.firstName.trim() && find.contact.lastName.trim()) info = `${find.contact.firstName} ${find.contact.lastName}`
                    else info= `${find.contact.email}`
                }
            } else if (typeof opportunity == 'string') {
                let find = this.opportunities.find(x => x.id == opportunity)
                if (find && find.contact) {
                    if (find.contact.firstName && find.contact.lastName && find.contact.firstName.trim() && find.contact.lastName.trim()) info = `${find.contact.firstName} ${find.contact.lastName}`
                    else info= `${find.contact.email}`
                }
            }
            return info
        },
        deleteAppointment(obj) {
            if (this.$p < 40 && (!obj.createdBy || obj.createdBy.id !== this.$store.state.user.user.id)){
                return this.$message.error('You do not have permission to delete Appointments')
            }
            let self = this
            this.$confirm({
                title: 'Delete Appointment',
                content: h => <div>Do you want to delete this appointment?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    self.$api.delete(`/appointments/:instance/${obj.id}`).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        self.$store.commit('DELETE_APPOINTMENT',data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        updatedAppointment(){
            console.log('UPDATE APPOINTMENT RAN')
            this.test = Date.now()
        },
        cancelEditAppointment(){
            this.$store.commit('CLOSE_APPOINTMENT')
        },
        editAppointment(item) {
            this.$store.commit('EDIT_APPOINTMENT', item)
            if (item.contact && item.contact.id) {
                setTimeout(() => {
                    this.$store.commit('UPDATE_QUERY_CONTACT', item.contact)
                }, 50)
            }
        },
        handleChange(pagination, filter, sorter) {
            if (!sorter.order) this.sortItem = {}
            else if(sorter.columnKey) {
                this.sortItem = {
                    key:sorter.columnKey,
                    order:sorter.order
                }
            }
            console.log('sort item', this.sortItem)
        },
        getType(typeId) {
            let find = this.appointmentTypes.find(x => {
                if (x.id && x.id == typeId) return x
                else if (x.value && x.value == typeId) return x
            })
            if (find) return find.name
        },
        onSelectChange(selectedRowKeys) {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },
        convertDate(num){
            let x = new Date(num)
            //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			x.setTime(x.getTime() + this.offsetDiffMs('get'));
            let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
        },
        convertTime(time){
            let x = new Date(time)
			x.setTime(x.getTime() + this.offsetDiffMs('get'));
            let hours = x.getHours()
            let minutes = x.getMinutes()
			minutes = minutes < 10 ? '0'+minutes : minutes

			let strTime = hours + ':' + minutes

			if (this.timeFormat == 12){
				let ampm = hours >= 12 ? 'PM' : 'AM'
				hours = hours % 12
				hours = hours ? hours : 12 // the hour '0' should be '12'
				strTime = hours + ':' + minutes + ' ' + ampm
			}

            return strTime
        },
		bulkDelete() {
			if (this.$p < 40){
				return this.$message.error('You do not have permission to delete appointments')
			}
			let self = this;
			this.$confirm({
				title: 'Delete Appointments',
				content: h => <div>Do you want to delete the selected appointments? All information related to them will be lost.</div>,
				okText: 'DELETE',
				okType: 'danger',
				cancelText: 'CANCEL',
				centered: true,
				onOk(){
					self.$api.put(`/appointments/:instance/bulk-delete`, {ids: self.selectedRowKeys}).then(({data}) => {
						if(data.success) {
							self.$store.commit('BULK_DELETE_APPOINTMENT', self.selectedRowKeys)
							self.selectedRowKeys = []
						}
					}).catch((error) => {
						console.log('Error', error);
					})
				}
			})
		},
    },
    created() {
        this.$store.commit('SIDEBAR_SELECTION',{value:'Appointments'})
		if (this.$route.query && this.$route.query.id) {
			const appointment = this.appointments.find(a => a.id === this.$route.query.id)
			if (appointment) {
				setTimeout(() => {
					this.$store.commit('OPEN_PREVIEW_MODAL', {type:'appointment', object:appointment})
				}, 0)
			}
		}
    },
}
</script>

<style scoped>
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
    }    .popoverContent{
        height: 35px;
        /* width: 160px; */
        line-height: 35px;
        padding: 0 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
        color:black;
    }
</style>
